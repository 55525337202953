<template>
  <b-modal
    v-model="showModal"
    title="Add Buyer Ticket"
    size="xl"
    no-close-on-backdrop
    @hidden="$emit('modal-closed')"
    @ok="submit"
  >
    <div
      v-if="loading"
      class="text-center"
    >
      <b-spinner
        label="Small Spinner"
        variant="primary"
      />
    </div>

    <b-alert
      variant="danger"
      :show="loadingError ? true : false"
    >
      <div class="alert-body">
        <p>
          {{ loadingError }}
        </p>
      </div>
    </b-alert>

    <div v-if="!loading && !loadingError">

      <b-form
        @submit.prevent="submit"
      >
        <validation-observer ref="buyerTicketForm">

          <b-row>
            <b-col
              md="6"
            >
              <validation-provider
                #default="{ errors }"
                name="Buyer"
                :vid="'buyer'"
                rules="required"
              >
                <b-form-group
                  label="Buyer"
                  :state="errors.length > 0 ? false : null"
                >
                  <custom-v-select
                    v-model="buyerId"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="buyerOptions"
                    label="text"
                    placeholder="Select Buyer"
                    :state="errors.length > 0 ? false:null"
                    :reduce="option => option.id"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Departments"
                vid="departments"
                rules="required"
              >
                <b-form-group
                  label="Departments"
                  :state="errors.length > 0 ? false : null"
                >
                  <custom-v-select
                    v-model="departments"
                    multiple
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="displayDepartmentOptions"
                    label="text"
                    placeholder="Select Departments"
                    :clearable="false"
                    :reduce="option => option.id"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <ticket-discrepancies
            v-model="discrepancies"
            :item-options="itemOptions"
            :party-options="buyerOptions"
            is-buyer-ticket
          />
        </validation-observer>
      </b-form>
    </div>

    <template #modal-footer="{ ok, cancel }">
      <b-button
        variant="secondary"
        @click="cancel()"
      >
        Cancel
      </b-button>
      <b-button
        variant="primary"
        :disabled="saving || loading || loadingError ? true : false"
        @click="ok()"
      >
        Submit
        <b-spinner
          v-if="saving"
          small
          label="Small Spinner"
        />
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  BModal, BRow, BCol, BFormGroup, BForm, BSpinner, BAlert, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import CustomVSelect from '@/components/UI/CustomVSelect.vue'
import TicketDiscrepancies from '@/components/Tickets/TicketDiscrepancy/TicketDiscrepancies.vue'

export default {
  components: {
    CustomVSelect,
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BButton,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    BAlert,
    TicketDiscrepancies,
  },
  data() {
    return {
      loading: false,
      loadingError: null,
      showModal: true,
      buyerId: null,
      buyerOptions: [],
      departments: [],
      departmentOptions: [],
      saving: false,
      itemOptions: [],
      discrepancies: [],
    }
  },
  computed: {
    displayDepartmentOptions() {
      return this.departmentOptions.map(option => ({
        id: option,
        text: option.charAt(0).toUpperCase() + option.slice(1),
      }))
    },
  },
  created() {
    this.initializeForm()
  },
  methods: {
    async submit(event) {
      event.preventDefault()

      const isFormValid = await this.$refs.buyerTicketForm.validate()

      if (!isFormValid) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Please correct the form errors',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        return
      }

      if (!this.discrepancies.length) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Atleast one discrepancy is required to create a ticket.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        return
      }

      this.saving = true
      const formData = {
        buyer: this.buyerId,
        departments: this.departments,
        buyer_discrepancies: this.discrepancies.map(item => ({
          type: item.type,
          details: item.details,
          buyer_discrepancy_remarks: item.discrepancy_remarks.map(remarks => ({
            ...remarks,
          })),
        })),
      }

      try {
        await axios.post('/tickets/buyer-ticket/', formData)

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Ticket created successfully',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.$refs.buyerTicketForm.reset()
        this.saving = false
        this.showModal = false
        this.$emit('saved')
      } catch (error) {
        this.$refs.buyerTicketForm.reset()

        const statusCode = error?.response?.status
        const serverErrors = error?.response?.data
        if (statusCode === 400 && serverErrors) {
          this.$refs.buyerTicketForm.setErrors(serverErrors)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Please correct the form errors',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error?.response?.data?.detail || 'Error saving ticket',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
        this.saving = false
      } finally {
        this.saving = false
      }
    },
    async initializeForm() {
      this.loading = true
      try {
        const res = await axios.get('/accounts/buyers_options')
        this.buyerOptions = res.data.data
      } catch (error) {
        this.loadingError = error?.response?.data?.detail || 'Error fetching buyer options'
        this.loading = false
      }

      try {
        const res = await axios.get('/item_options')
        this.itemOptions = res.data.data
      } catch (error) {
        this.loadingError = error?.response?.data?.detail || 'Error fetching item options'
        this.loading = false
      }

      try {
        const res = await axios.get('/tickets/department_options')
        this.departmentOptions = res.data.departments
      } catch (error) {
        this.loadingError = error?.response?.data?.detail || 'Error fetching department options'
      }
      this.loading = false
    },

  },
}
</script>
